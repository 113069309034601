import React from 'react'
import { CBadge } from '@coreui/react'
import { NavLink } from 'react-router-dom'

// CUSTOM IMPORTS
import { INavigation, INavigationBadge } from '../../types'
import { NavigationPermissions } from './Navigation'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../features/authSlice'

interface IProps {
  items: INavigation[]
}

export const AppSidebarNav: React.FC<IProps> = ({ items }): JSX.Element => {
  const navLink = (name: string, icon?: JSX.Element, badge?: INavigationBadge): JSX.Element => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item: INavigation, index: number) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
          component: NavLink,
          // activeClassName: 'active',
        })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item: INavigation, index: number) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        // toggler={navLink(name, icon)}
        // visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item: INavigation, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  const user = useSelector(selectAuth);
  const userType = user?.type ?? "";
  const filterItems = items.filter(item => NavigationPermissions[userType]?.includes(item.name) )

  return (
    <React.Fragment>
      {filterItems &&
        filterItems.map((item: INavigation, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

// AppSidebarNav.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.any).isRequired,
// }
