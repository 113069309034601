import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_LOGIN, BASE_URL } from '../Constant';

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body: {email: string; password: string}) => {
                return {
                    url: API_LOGIN,
                    method: "post",
                    body,
                }
            }
        })
    })
});

export const {useLoginUserMutation} = authApi;