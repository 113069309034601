import { CSpinner } from '@coreui/react';
import React from 'react';

const Loader = () => {

  return (
    <div className="loaderBackdrop">
      <div className="loaderSpinner">
        <CSpinner/>
      </div>
  </div>
  );
}

export default Loader;
