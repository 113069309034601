import { toast } from "react-toastify";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";

export default class Notification {
    private validator = new Validator();
    private logger =  new Logger();
    public success(data: string) : void {
        try{
            this.validator.isValidString('data', data);
            toast.success(data);
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    public error(data: string) : void {
        try{
            this.validator.isValidString('data', data);
            toast.error(data);
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}