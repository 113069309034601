import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface AuthSate {
    token: string | null;
    name: string | null;
    type: number | null;
}

const user = JSON.parse(localStorage.getItem('user') ?? '{}');

const initialState: AuthSate = {
    token: user?.token ?? null,
    type: user?.type ?? null,
    name: user?.name ?? null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthSate>) => {
            localStorage.setItem("user", JSON.stringify({
                token: action.payload.token,
                type: action.payload.type,
                name: action.payload.name,
            })
        );
        state.token = action.payload.token;
        state.type = action.payload.type;
        state.name = action.payload.name;
        },
        logout: (state) => {
            localStorage.clear();
            state.token = null;
            state.type = null;
            state.name = null;
        },
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const {setUser, logout} = authSlice.actions;

export default authSlice.reducer;