export default class StorageServices {
    store(key: string, value: any){
        if(this.isLocalStorageExists()){
            localStorage.setItem(key, value);
        }
    }
    get(key: string){
        if(this.isLocalStorageExists()){
            return localStorage.getItem(key);
        }
    }
    remove(key: string){
        if(this.isLocalStorageExists()){
            localStorage.removeItem(key);
        }
    }
    hasKey(key: string){
        if(this.isLocalStorageExists()){
            return localStorage.getItem(key) !== null;
        }
    }
    isLocalStorageExists(){
        try {
            let test = 'test';
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch(e) {
            return false;
        }
    }
}
