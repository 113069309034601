import React, { useState } from "react"
import { AppSidebar } from "./AppSidebar"
import { AppFooter } from "./AppFooter"
import { AppHeader } from "./AppHeader"
import { AppContent } from "./AppContent"

interface IProps {
  children?: React.ReactNode 
}

export const Layout: React.FC<IProps> = ({ children }): JSX.Element => {
  const [sidebarUnfoldable, setSidebarUnfoldable] = useState<boolean>(false)
  const [sidebarShow, setSidebarShow] = useState<boolean>(true)


  return (
    <div>
      <AppSidebar
        sidebarShow={sidebarShow}
        setSidebarShow={setSidebarShow}
        sidebarUnfoldable={sidebarUnfoldable}
        setSidebarUnfoldable={setSidebarUnfoldable}
      />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
        <div className="body flex-grow-1 px-3">
          <AppContent children={children} />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}
