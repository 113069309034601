import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import { Routing } from './components'
function App() {
  return (
    <Routing/>
  )
}

export default App
