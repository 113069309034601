import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { RoutePermissions, defaultRoutes, routes } from "../../routes"
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/authSlice";

export const Routing = (): JSX.Element => {
  const user = useSelector(selectAuth);
  const userType = user?.type ?? "";
  const filterRoutes: any[] = userType ? routes.filter(item => RoutePermissions[userType]?.includes(item.name)) : defaultRoutes;

  return (
    <BrowserRouter>
      <Routes >
        {filterRoutes.map(({ path, element: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}
