import React from 'react'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { useLocation } from 'react-router-dom'


/* CUSTOM */
import { routes } from '../../routes'
import { IBreadcrumbs } from '../../types'


export const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname: string) => {
    const currentRoute = routes.find(route => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location: string) => {
    const breadcrumbs: IBreadcrumbs[] = []
    location.split('/').reduce((prev: string, curr: string, index: number, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      {currentLocation !== '/dashboard' && <CBreadcrumbItem href="/">Dashboard</CBreadcrumbItem>}
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })} key={index} >
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}
