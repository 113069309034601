import React from 'react'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'


import { Navigation } from './Navigation'
import { Language } from '../../language/English'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../features/authSlice'

interface IProps {
  sidebarUnfoldable: boolean,
  setSidebarShow: (status: boolean) => void,
  sidebarShow: boolean,
  setSidebarUnfoldable: (status: boolean) => void
}

export const AppSidebar: React.FC<IProps> = ({
  sidebarUnfoldable,
  setSidebarShow,
  sidebarShow,
  setSidebarUnfoldable
}) => {
  const user = useSelector(selectAuth);
  const userName = user?.name ?? "";
  
  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => setSidebarShow(visible)}
    >
      <CSidebarBrand className="d-md-flex flex-column">
        <h5 className="pt-3">
          { 
            Language.PANEL_NAME
          }
        </h5>
        <p className="client-name px-2"> {Language.HELLO} {userName}!</p>
      </CSidebarBrand>
      <CSidebarNav>
        <AppSidebarNav items={Navigation} />
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => setSidebarUnfoldable(!sidebarUnfoldable)}
      />
    </CSidebar>
  )
}