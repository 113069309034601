import LogLevel from "loglevel";
export default class Logger {
    public error(log: any) : void{
        LogLevel.error(log);
    }
    public info(log: any) : void{
        LogLevel.info(log);
    }
    public warn(log: any) : void{
        LogLevel.warn(log);
    }
}