import { CModal, CButton, CModalFooter, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';


type confirmationBoxCallBackFunctionType = (paramOne: any) => void;

const ConfirmationBoxModal = (props : {
  title:string, message: string, buttonOneText: string,   buttonTwoText: string, buttonOneCallable: confirmationBoxCallBackFunctionType, 
  buttonTwoCallable: confirmationBoxCallBackFunctionType, buttonOneCallBackFunctionParams: any, buttonTwoCallBackFunctionParams: any}) => 
  {
      return (
        <>
        <CModal scrollable visible={true}>
        <CModalHeader>
            <CModalTitle>{props.title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="statusConfirmation">
              <p>{props.message}</p>
          </div>
          <CModalFooter>
            <CButton color="light" onClick={() => props.buttonOneCallable(props.buttonOneCallBackFunctionParams)}>{props.buttonOneText}</CButton>
            <CButton color="secondary" onClick={() => props.buttonTwoCallable(props.buttonTwoCallBackFunctionParams)}>
              {props.buttonTwoText}
            </CButton>
          </CModalFooter>
        </CModalBody>
        </CModal>
      </>
      );
  }

export default ConfirmationBoxModal;
