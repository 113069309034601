import validator from 'validator';
import Logger from '../Logger/Logger';
export default class Validator {
    private logger = new Logger();
    public isValidString(name : string, data: string, throwException : boolean = true ) : boolean{
        try {
            let result = true;
            if(validator.isEmpty(data)){
                if(throwException){
                    throw new Error(`ERROR in class Validator.isValidString(). ${name} is an empty string.`);
                }
                result = false;
            }
            return result;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    public isNumberGreaterThan(name : string, numValue: number, comparison : number = 0, throwException : boolean = true ) : boolean{
        try {
            let result = false;
            if(numValue > comparison){
                result = true;
            }
            if(result === false && throwException){
                throw new Error(`ERROR in class Validator.isNumberGreaterThan(). ${name}(${numValue}) is not greater than ${comparison}.`);
            }
            return result;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    public validatePositiveInteger(number: number, throwException : boolean = true) : boolean
    {
        try {
            let result = true;
            if(!number || !Number.isInteger(number) || number < 1){
                result = false;
            }
            
            if(result === false && throwException){
                throw new Error(`ERROR in class Validator.validatePositiveInteger(). ${number}(${number}) is not int.`);
            }
            return result;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    public validateIntegerIncludingZero(number: number, throwException : boolean = true) : boolean
    {
        try {
            let result = true;
            if(!Number.isInteger(number)){
                result = false;
            }
            
            if(result === false && throwException){
                throw new Error(`ERROR in class Validator.validateIntegerIncludingZero(). ${number}(${number}) is not int.`);
            }
            return result;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    
}